const config = {
  aws_project_region: "eu-central-1",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_K37Ux4VbU",
  aws_user_pools_web_client_id: "d1f52jg61euk0bivm603bh10m",
  oauth: {
    domain: "auth.dev.dwm.navify.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: `${window.location?.origin}/`,
    redirectSignOut: `${window.location?.origin}/`,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: "https://api.dev.dwm.navify.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  pipeline_id: "18140364",
  commit_hash: "e58f1051",
};

export const federated = {
  googleClientId: "Roche",
};

export default config;
